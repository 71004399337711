<template>
  <Layout
    :awcEnv="awcEnv"
    :loginUserData="loginUserData"
    :requestResponseData="requestResponseData"
    @changeLoginUserData="onChangeLoginUserData"
    @changeAwcEnv="onChangeAwcEnv"
  >
    <RouterView
      :awcEnv="awcEnv"
      :loginUserData="loginUserData"
      :requestResponseData="requestResponseData"
      @changeLoginUserData="onChangeLoginUserData"
      @changeAwcEnv="onChangeAwcEnv"
    />
  </Layout>
</template>

<script>
import { ref, reactive } from 'vue'
import Layout from '@/components/TSSW/Layout'

export default {
  name: 'TSSW',
  components: {
    Layout,
  },
  setup() {
    const awcEnv = ref('UAT')
    const loginUserData = reactive({})

    const requestResponseData = reactive({
      url: '',
      request: '',
      response: '',
    })

    const onChangeLoginUserData = (data) => {
      console.log('onChangeLoginUserData', data)
      Object.assign(loginUserData, data)
    }

    const onChangeAwcEnv = env => awcEnv.value = env

    return {
      awcEnv,
      loginUserData,
      requestResponseData,
      onChangeLoginUserData,
      onChangeAwcEnv,
    }
  },
}
</script>
