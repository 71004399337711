<template>
  <AntConfigProvider>
    <AntLayout>
      <Sider
        :logoImg="require('@/assets/tssw_logo.svg')"
        logoLink="/tssw/login"
        :theme="awcEnvList.find(({ key }) => key === awcEnv).theme"
        :envList="awcEnvList"
        :envSelectedKey="awcEnv"
        :onClickEnv="onClickAwcEnv"
        :menuList="menuList"
        :menuOpenKeys="menuOpenKeys"
        :menuSelectedKeys="menuSelectedKeys"
      />
      <AntLayout class="layout">
        <AntLayoutContent class="content">
          <slot></slot>
        </AntLayoutContent>

        <Footer :requestResponseData="requestResponseData" />
      </AntLayout>
    </AntLayout>
  </AntConfigProvider>
</template>

<script>
import {
  h,
  ref,
  reactive,
  computed,
} from 'vue'

import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'

import {
  ConfigProvider as AntConfigProvider,
  Layout as AntLayout,
} from 'ant-design-vue'

import UserIcon from '@ant-design/icons-vue/lib/icons/UserOutlined'
import LoginIcon from '@ant-design/icons-vue/lib/icons/ExportOutlined'
import LogoutIcon from '@ant-design/icons-vue/lib/icons/ImportOutlined'
import HomeIcon from '@ant-design/icons-vue/lib/icons/HomeOutlined'
import DollarIcon from '@ant-design/icons-vue/lib/icons/DollarOutlined'
import TransactionIcon from '@ant-design/icons-vue/lib/icons/TransactionOutlined'
import UserAddIcon from '@ant-design/icons-vue/lib/icons/UserAddOutlined'
import SolutionIcon from '@ant-design/icons-vue/lib/icons/SolutionOutlined'

import Sider from '@/components/AgentManagement/Layout/Sider'
import Footer from '@/components/AgentManagement/Layout/Footer'

import { AWC_ENV_LIST } from '@/constants/index'
import { AGENT_CONFIG } from '@/constants/tssw'

export default {
  name: 'Layout',
  components: {
    AntConfigProvider,
    AntLayout,
    AntLayoutContent: AntLayout.Content,
    Sider,
    Footer,
  },
  props: {
    awcEnv: String,
    loginUserData: Object,
    requestResponseData: Object,
  },
  inheritAttrs: false,
  emits: ['changeAwcEnv', 'changeLoginUserData'],
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()

    const menuOpenKeys = ref(['user'])
    const menuSelectedKeys = computed(() => [route.path.replace('/tssw/', '')])

    const loginUserData = reactive(props.loginUserData)
    const requestResponseData = reactive(props.requestResponseData)

    const awcEnvList = Object.keys(AGENT_CONFIG).map((key) => {
      const awcEnvTitleLookup = {
        AWCA: 'AWCA (Test line)',
        AWCA_RP: 'AWCA (Formal line)',
        AWS03: 'AWS03 (Test line)',
        AWS03_RP: 'AWS03 (Formal line)',
        AWS06: 'AWS06 (RT only)',
      }

      const title = awcEnvTitleLookup[key] || key

      let theme = 'walnut'
      if (key === 'UAT') theme = 'navy'
      if (key === 'STG') theme = 'teal'

      return { key, title, theme }
    })

    const onClickLogout = async () => {
      const { awcEnv } = props

      const requestData = {
        cert: AGENT_CONFIG[awcEnv].cert,
        agentId: AGENT_CONFIG[awcEnv].agentId,
        userIds: loginUserData.userId,
      }

      const URL = `${AWC_ENV_LIST[awcEnv].domain}/wallet/logout`
      const { data: { data: awcData } } = await axios.post(`/proxy/${URL}`, requestData)

      if (awcData) {
        requestResponseData.url = URL
        requestResponseData.request = JSON.stringify(requestData)
        requestResponseData.response = JSON.stringify(awcData)

        if (awcData.status === '0000') {
          emit('changeLoginUserData', { userId: null })
          router.push('/tssw/login')
        }
      }
    }

    const onClickAwcEnv = async ({ key }) => {
      if (loginUserData.userId) await onClickLogout()
      emit('changeAwcEnv', key)
    }

    const userSubMenuList = [
      {
        key: 'enter-game',
        title: 'Enter Game',
        icon: h(HomeIcon),
        link: '/tssw/enter-game',
      },
      {
        key: 'user-settings',
        title: 'User Settings',
        icon: h(DollarIcon),
        link: '/tssw/user-settings',
      },
      {
        key: 'update-bet-limit',
        title: 'Update Bet Limit',
        icon: h(TransactionIcon),
        link: '/tssw/update-bet-limit',
      },
      {
        key: 'logout',
        title: 'Logout',
        icon: h(LogoutIcon),
        onClick: onClickLogout,
      },
    ]

    const menuList = computed(() => [
      loginUserData.userId ? {
        key: 'user',
        title: loginUserData.userId,
        icon: h(UserIcon),
        subMenu: userSubMenuList,
      } : {
        key: 'login',
        title: 'Login',
        icon: h(LoginIcon),
        link: '/tssw/login',
      },
      {
        key: 'create-member',
        title: 'Create Member',
        icon: h(UserAddIcon),
        link: '/tssw/create-member',
      },
      {
        key: 'member-list',
        title: 'Member List',
        icon: h(SolutionIcon),
        link: '/tssw/member-list',
      },
    ])

    return {
      awcEnvList,
      onClickAwcEnv,
      menuList,
      menuOpenKeys,
      menuSelectedKeys,
    }
  },
}
</script>

<style scoped>
.layout {
  height: 100vh;
}

.content {
  padding: 64px 24px;
  overflow-y: auto;
}

.content:has(.iframe-wrapper) {
  padding: 0px;
}
</style>
