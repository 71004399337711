<template>
  <AntLayoutFooter class="footer">
    <ResquestResponseConsole :requestResponseData="requestResponseData" />
  </AntLayoutFooter>
</template>

<script>
import { Layout as AntLayout } from 'ant-design-vue'

import ResquestResponseConsole from '../ResquestResponseConsole'

export default {
  name: 'Footer',
  components: {
    AntLayoutFooter: AntLayout.Footer,
    ResquestResponseConsole,
  },
  props: {
    requestResponseData: Object,
  },
}
</script>

<style scoped>
.footer {
  padding: 0;
}
</style>
