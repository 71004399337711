<template>
  <AntLayoutSider
    width="220px"
    :class="['sider', `${theme}-theme`]"
    v-model:collapsed="collapsed"
    collapsible
  >
    <div class="logo">
      <router-link :to="logoLink">
        <img :src="logoImg" />
      </router-link>
    </div>

    <div class="env">
      <slot name="env"></slot>

      <AntDropdown v-if="envList" placement="bottomCenter">
        <AntButton> {{ selectedEnv.title }}</AntButton>
        <template #overlay>
          <AntMenu @click="onClickEnv">
            <template v-for="{ key, title } in envList" :key="key">
              <AntMenuItem>
                {{ title }}
              </AntMenuItem>
            </template>
          </AntMenu>
        </template>
      </AntDropdown>
    </div>

    <AntMenu
      theme="dark"
      mode="inline"
      :class="['menu', `${theme}-theme`]"
      :selectedKeys="menuSelectedKeys"
      :openKeys="menuOpenKeys"
    >
      <template
        v-for="({ key, title, subMenu, link, icon, onClick }) in menuList"
        :key="key"
      >

        <AntSubMenu
          v-if="subMenu"
          :key="subMenu ? key : `_${key}`"
          :popupClassName="['menu', `${theme}-theme`]"
          @click="onClick"
        >
          <template #title>
            <component v-if="icon" :is="icon"/>
            <span class="nav-text">
              <router-link v-if="link" :to="link">
                {{ title }}
              </router-link>
              <template v-else>{{ title }}</template>
            </span>
          </template>

          <AntMenuItem
            v-for="({ key, title, icon, link, onClick }) in subMenu"
            :key="key"
            @click="onClick"
          >
            <component v-if="icon" :is="icon"/>
            <span class="nav-text">
              <router-link v-if="link" :to="link">
                {{ title }}
              </router-link>
              <template v-else>{{ title }}</template>
            </span>
          </AntMenuItem>
        </AntSubMenu>

        <AntMenuItem
          v-else
          :key="key"
          @click="onClick"
        >
          <component v-if="icon" :is="icon"/>
          <span class="nav-text">
            <router-link v-if="link" :to="link">
              {{ title }}
            </router-link>
            <template v-else>{{ title }}</template>
          </span>
        </AntMenuItem>

      </template>
    </AntMenu>

    <!-- <AntTooltip
      :title="`Click to switch to ${awcEnv === 'UAT' ? 'STG' : 'UAT'}`"
      color="rgba(100,100,100,0.8)"
      placement="right"
    >
      <AntButton
        class="env-btn"
        size="small"
        @click="onClickAwcEnv"
      >
        {{ awcEnv }}
      </AntButton>
    </AntTooltip> -->

  </AntLayoutSider>
</template>

<script>
import {
  ref,
  computed,
} from 'vue'

import {
  Layout as AntLayout,
  Menu as AntMenu,
  Button as AntButton,
  Dropdown as AntDropdown,
} from 'ant-design-vue'

export default {
  name: 'Sider',
  components: {
    AntLayoutSider: AntLayout.Sider,
    AntMenu,
    AntMenuItem: AntMenu.Item,
    AntSubMenu: AntMenu.SubMenu,
    AntButton,
    AntDropdown,
  },
  props: {
    logoLink: String,
    logoImg: String,
    awcEnv: String,
    theme: String,
    envList: Array,
    envSelectedKey: String,
    onClickEnv: Function,
    menuList: Array,
    menuOpenKeys: Array,
    menuSelectedKeys: Array,
    loginUserData: Object,
    requestResponseData: Object,
  },
  emits: ['menuOpenChange'],
  setup(props) {
    const collapsed = ref(false)
    const selectedEnv = computed(() => props.envList.find(({ key }) => key === props.envSelectedKey) || {})

    return {
      collapsed,
      selectedEnv,
    }
  },
}
</script>

<style scoped>
.sider {
  overflow: auto;
  height: 100vh;
}

.logo {
  text-align: center;
}

.logo img {
  width: 75%;
  cursor: pointer;;
}

.nav-text {
  margin: 0 10px;
}

.menu .ant-menu-item a, .ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.65);
}

.menu .ant-menu-item a:hover, .ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
</style>

<style>
.env {
  text-align: center;
  margin-bottom: 16px;
}

.env .ant-btn {
  font-size: 12px;
  height: 20px;
  padding: 0px 5px;
  background: none;
  color: rgba(255, 255, 255, 0.6);
  border-color: rgba(255, 255, 255, 0.6);
  margin: 0 4px;
}

.env .ant-btn:hover {
  color: rgba(255, 255, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}

.teal-theme, .teal-theme .ant-menu.ant-menu-dark {
  background: #153334;
}

.teal-theme.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #58b6c5;
}

.teal-theme.ant-menu-dark .ant-menu-sub {
  background: #001715;
}

.walnut-theme, .walnut-theme .ant-menu.ant-menu-dark {
  background: #342615;
}

.walnut-theme.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #c59458;
}

.walnut-theme.ant-menu-dark .ant-menu-sub {
  background: #170b00;
}

.ant-layout-sider-trigger {
  background: rgba(255, 255, 255, 0.05);
}
</style>
