<template>
  <div class="console">

    <div class="title" @click="handleCollapsed">
      <div>
        <CodeIcon /> Console
      </div>
      <div>
        <CaretUpIcon v-show="collapsed" />
        <CaretDownIcon v-show="!collapsed" />
      </div>
    </div>

    <div v-show="!collapsed">
      <div v-show="url" class="url">
          Request URL: {{ url }}
      </div>
      <div class="blocks">
        <div v-show="request" class="block">
          <div class="block-title">Request Body</div>
          <div class="block-body">
            <pre class="json-code">{{ prettifyJsonString(request) }}</pre>
          </div>
        </div>
        <div v-show="response" class="block">
          <div class="block-title">Response Body</div>
          <div class="block-body">
            <pre class="json-code">{{ prettifyJsonString(response) }}</pre>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, toRefs } from 'vue'

import CodeIcon from '@ant-design/icons-vue/lib/icons/CodeOutlined'
import CaretUpIcon from '@ant-design/icons-vue/lib/icons/CaretUpOutlined'
import CaretDownIcon from '@ant-design/icons-vue/lib/icons/CaretDownOutlined'

import prettifyJsonString from '@/utils/prettifyJsonString'

export default {
  name: 'ResquestResponseConsole',
  props: {
    requestResponseData: Object,
    defaultCollapsed: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CodeIcon,
    CaretUpIcon,
    CaretDownIcon,
  },
  // inheritAttrs: false,
  setup(props) {
    const collapsed = ref(props.defaultCollapsed)
    const handleCollapsed = () => { collapsed.value = !collapsed.value }

    return {
      collapsed,
      handleCollapsed,
      prettifyJsonString,
      ...toRefs(props.requestResponseData),
    }
  },
}
</script>

<style scoped>
.console {
  /* width: 100%; */
  position: relative;
  font-size: 12px;
  background: #fff;
  border-top: 1px solid #DCDEE1;
  box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.title {
  display: flex;
  justify-content: space-between;
  background: #F1F3F4;
  padding: 2px 8px;
  cursor: pointer;
}

.url {
  padding: 2px 8px;
  border-bottom: 1px solid #eee;
}

.blocks {
  display: flex;
}

.block {
  width: 100%;
}

.block:first-child {
  border-right: 1px solid #eee;
}

.block-title {
  padding: 2px 8px;
  border-bottom: 1px solid #eee;
}

.block-body {
  color: rgba(0, 0, 0, 0.7);
  max-height: 250px;
  overflow: auto;
}

.json-code {
  padding: 8px;
  height: 100%;
  word-break: break-word;
  white-space: break-spaces;
}
</style>
